import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import { LOGO_IMAGE_SRC_DARK, LOGO_IMAGE_SRC_LIGHT, LogoImage } from './LogoImage';

import styles from './index.module.scss';

export interface LogoPictureProps extends Omit<HTMLAttributes<HTMLAnchorElement>, 'href'> {
  readonly url: string;
  readonly lightLogoSrc?: string;
  readonly lightLogoSize?: { height: number; width: number };
  readonly darkLogoSrc?: string;
  readonly darkLogoSize?: { height: number; width: number };
}

export function LogoPicture({
  url,
  lightLogoSrc = LOGO_IMAGE_SRC_LIGHT,
  lightLogoSize,
  darkLogoSrc = LOGO_IMAGE_SRC_DARK,
  darkLogoSize,
  className,
  ...props
}: LogoPictureProps): JSX.Element {
  return (
    <a href={url} className={classNames(LogoPicture.displayName, styles.component)} {...props}>
      <LogoImage
        src={lightLogoSrc}
        className={styles.desktop}
        width={lightLogoSize?.width}
        height={lightLogoSize?.height}
      />
      <LogoImage
        src={darkLogoSrc}
        className={styles.mobile}
        width={darkLogoSize?.width}
        height={darkLogoSize?.height}
      />
    </a>
  );
}
LogoPicture.displayName = 'LogoPicture';
