import type { HTMLAttributes } from 'react';
import React from 'react';

interface ResourceHintDefinition {
  readonly url: string;
  readonly rel: HTMLLinkElement['rel'];
  readonly as?: HTMLLinkElement['as'];
}

export interface ResourceHintsProps extends HTMLAttributes<HTMLLinkElement> {
  readonly resources: ResourceHintDefinition[];
}

export function ResourceHints({ resources, ...props }: ResourceHintsProps): JSX.Element {
  return (
    <>
      {resources.map(({ url, rel, as }) => {
        const asProp = rel === 'preload' || rel === 'prefetch' ? as : undefined;
        const crossoriginProp = asProp === 'font' || asProp === 'fetch' ? 'anonymous' : undefined;

        return <link key={url} rel={rel} href={url} as={asProp} crossOrigin={crossoriginProp} {...props} />;
      })}
    </>
  );
}
ResourceHints.displayName = 'ResourceHints';
