/* eslint-disable class-methods-use-this */
import type { CSSProperties } from 'react';
import React from 'react';
import dynamic from 'next/dynamic';
import HydrationContext from './HydrationContext';
import {
  HYDRATION_ID_ATTRIBUTE,
  HYDRATION_TRIGGER_ATTRIBUTE,
  INTERSECTION_OBSERVER_ROOT_MARGIN,
} from './constants';
import type HydrationManager from './HydrationManager';

export interface DeferHydrationOptions {
  readonly hydrationId: string;
  readonly trigger:
    | 'view'
    | 'delay'
    /**
     * @deprecated doesn't work well with next/image, use `view` instead
     */
    | 'ssr';
  readonly delay?: number;
  readonly style?: CSSProperties;
}

/**
 * This works only at page level, it will behave like a normal import if used inside components.
 *
 * @deprecated use NextJs Server Side Components
 */
const deferHydration = (
  importFn: any,
  { hydrationId, trigger, delay, style = {} }: DeferHydrationOptions
): any => {
  if (!hydrationId) throw new Error('deferHydration needs option `hydrationId`');
  if (!trigger) throw new Error('deferHydration needs option `trigger`: view, delay, ssr');
  else if (trigger === 'delay' && !Number.isInteger(delay)) {
    throw new Error('deferHydration needs option `delay` in ms');
  }

  let Component: any = !process.browser || trigger === 'ssr' ? dynamic(importFn) : null;
  let delayTimeout: NodeJS.Timeout;

  class Hydrator extends React.Component {
    private ref: React.RefObject<any>;

    // eslint-disable-next-line react/state-in-constructor
    state = {
      shouldHydrate: false,
    };

    constructor(props: any) {
      super(props);
      this.ref = React.createRef();
    }

    componentDidMount() {
      switch (trigger) {
        case 'view':
          this.hydrateOnView();
          break;
        case 'delay':
          this.hydrateAfterDelay();
          break;
        case 'ssr':
          // on client
          // the script of the component will not load
          // and the component will not be hydrated
          break;
        default:
          break;
      }
    }

    componentWillUnmount() {
      if (delayTimeout) clearTimeout(delayTimeout);
    }

    /**
     * Since the server has already done so much work rendering the page we'd
     * like to reuse as much as that as possible on the initial load. Ideally the
     * whole page would be static, making it very performant.
     *
     * So that we don't need to load all the JS on the page initially we will copy
     * the html returned by the server and use that instead. This is where the hydrationId comes in.
     *
     * The hydrationId MUST BE UNIQUE to the component that you are rendering.
     * This is so that we can lookup the html from the source code and return that instead of
     * the JS component, thus not downloading the chunk. If this is not unique, you will see
     * some strange behaviour like the footer rending before the header.
     *
     */
    get hydrationId() {
      return hydrationId;
    }

    /**
     * Call this when the component needs to be interactive. We will
     * then download the chunk...
     */
    hydrate = () => this.setState({ shouldHydrate: true });

    /**
     * If the user scrolls over the component and it's almost in view
     * we download the JS chunks and make it interactive.
     */
    hydrateOnView = () => {
      if (!process.browser) return;
      new IntersectionObserver(
        ([entry], obs) => {
          if (!entry.isIntersecting) return;
          if (!this.ref.current) return;
          obs.unobserve(this.ref.current);
          this.hydrate();
        },
        {
          // offset to trigger loading script a bit earlier
          rootMargin: INTERSECTION_OBSERVER_ROOT_MARGIN,
        }
      ).observe(this.ref.current);
    };

    /**
     * If you need to delay the download by X seconds to improve the experience
     * you can do so here.
     */
    hydrateAfterDelay = () => {
      if (!process.browser) return null;
      delayTimeout = setTimeout(() => {
        this.hydrate();
      }, delay);
      return delayTimeout;
    };

    /**
     * Here we determine if we should display the Component or it's static HTML.
     */
    render() {
      const { shouldHydrate } = this.state;
      const hydrationProps = {
        [HYDRATION_ID_ATTRIBUTE]: this.hydrationId,
        [HYDRATION_TRIGGER_ATTRIBUTE]: trigger,
      };
      const staticHtml = (this.context as HydrationManager).getHydrationHTML(this.hydrationId);
      const staticComponent = (
        <div
          key={hydrationId}
          style={style}
          ref={this.ref}
          suppressHydrationWarning
          {...hydrationProps}
          dangerouslySetInnerHTML={{ __html: staticHtml }}
        />
      );

      if (shouldHydrate && !Component) {
        /**
         * On the client, for triggers that get the component resources later
         * eg. trigger: view, delay
         */
        Component = dynamic(importFn, {
          /**
           * Show the ssr markup while loading component resources
           */
          // eslint-disable-next-line react/no-unstable-nested-components
          loading: () => staticComponent,
        });
      }

      let finalComponent = null;
      if (!process.browser) {
        /**
         * On the server, we will ALWAYS hydrate the component to generate SEO friendly source code.
         */
        finalComponent = <Component key={this.hydrationId} {...this.props} />;
        // eslint-disable-next-line react/destructuring-assignment
      } else if (this.state.shouldHydrate) {
        /**
         * On the client, when hydration is triggered, this will start downloading the resources
         * and once the resources are ready the component will be rendered.
         */
        finalComponent = <Component key={this.hydrationId} {...this.props} />;
      } else {
        /**
         * On the client, no hydration triggered, show ssr markup.
         */
        return staticComponent;
      }

      return (
        <div key={hydrationId} style={style} ref={this.ref} suppressHydrationWarning {...hydrationProps}>
          {finalComponent}
        </div>
      );
    }
  }

  Hydrator.contextType = HydrationContext;

  return Hydrator;
};

export default deferHydration;
