import { HYDRATION_ID_ATTRIBUTE } from './constants';

export default class HydrationManager {
  hydration = new Map();

  constructor() {
    if (!process.browser) return;
    for (const el of Array.from(document.querySelectorAll(`[${HYDRATION_ID_ATTRIBUTE}]`))) {
      this.hydration.set(el.getAttribute(HYDRATION_ID_ATTRIBUTE), el.innerHTML);
    }
  }

  getHydrationHTML(id: any) {
    return this.hydration.get(id);
  }
}
