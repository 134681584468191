import React from 'react';
import { Img } from '@/components/atoms/Img';

export const LOGO_IMAGE_SRC_LIGHT = '/logo/logo-dark-text-light-horizontal_mucglg.svg';
export const LOGO_IMAGE_SRC_DARK = '/logo/logo-light-text-dark-horizontal_buctbs.svg';

export interface LogoImageProps {
  readonly src: string;
  readonly width?: number;
  readonly height?: number;
  readonly className?: string;
}

export function LogoImage({ src, width = 113, height = 32, className }: LogoImageProps): JSX.Element {
  return (
    <Img
      src={src}
      alt="fixter logo"
      className={className}
      width={width}
      height={height}
      quality={75}
      provider="cloudinary"
    />
  );
}
