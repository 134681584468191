/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

import type { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Row } from '@/components/atoms/Grid';
import { LogoPicture } from '@/components/atoms/LogoPicture';
import { Icon } from '@/components/atoms/Icon';
import { Img } from '@/components/atoms/Img';
import type {
  NavigationTranslationaCta,
  NavigationTranslations,
} from './translations/NavigationTranslations';
import { MegaNavClient } from './client';
import styles from './index.module.scss';

interface BlogData {
  title: string;
  urlPath: string;
}

export interface MegaNavProps extends HTMLAttributes<HTMLElement> {
  readonly showLoginLink?: boolean;
  readonly navigationMenuFooter?: React.ReactNode;
  readonly darkLogoSrc?: string;
  readonly lightLogoSrc?: string;
  readonly isGarageMemberPage?: boolean;
  readonly dynamicBlogData: BlogData[];
  readonly translations: NavigationTranslations;
}

function MenuItem({
  label,
  htmlFor,
  desktopIcon,
  mobileIcon,
}: {
  label: string;
  htmlFor: string;
  desktopIcon: string;
  mobileIcon?: string;
}) {
  return (
    <div className={styles.linkContainer}>
      <label
        className={styles.link}
        htmlFor={htmlFor}
        tabIndex={0}
        aria-hidden="false"
        role="button"
        data-analytics={label}
      >
        <p>{label}</p>
        {mobileIcon && <Icon className={styles.mobileIcon} id={mobileIcon} legacy={false} />}
        <Icon className={styles.desktopIcon} id={desktopIcon} legacy={false} />
      </label>
    </div>
  );
}

function Cta({ cta, iconBackground }: { cta: NavigationTranslationaCta; iconBackground: string | string[] }) {
  const renderIcon = (icon: string, background: string) => (
    <div className={classNames(styles.iconContainer, styles.hideDesktop, styles[background])}>
      <Icon className={styles.icon} id={icon} legacy={false} height={16} width={16} />
    </div>
  );

  const icons = [];
  if (Array.isArray(cta.ICON)) {
    icons.push(...cta.ICON);
  } else if (cta.ICON) {
    icons.push(cta.ICON);
  }
  const backgrounds = Array.isArray(iconBackground) ? iconBackground : [iconBackground];

  return (
    <a href={cta.URL} aria-hidden="true" tabIndex={-1} className={styles.submenuCta}>
      <div className={styles.submenuCtaIcons}>
        {icons.length > 0 &&
          icons.map((icon, index) => renderIcon(icon, backgrounds[index] || backgrounds[0]))}
      </div>
      <div className={styles.submenuBodyContainer}>
        <p>{cta.HEADER}</p>
        {cta.DESCRIPTION && <p className={styles.info}>{cta.DESCRIPTION}</p>}
      </div>
      <Icon className={styles.arrowIcon} id="arrows/arrow-circle-broken-right" legacy={false} />
    </a>
  );
}

export function MegaNav({
  showLoginLink = true,
  navigationMenuFooter,
  children,
  className,
  darkLogoSrc = '/logo/logo-light-text-dark-horizontal_buctbs.svg',
  lightLogoSrc = '/logo/logo-dark-text-light-horizontal_mucglg.svg',
  isGarageMemberPage,
  dynamicBlogData,
  translations,
  ...props
}: MegaNavProps): JSX.Element {
  return (
    <nav id="nav" className={classNames(MegaNav.displayName, styles.nav, className)} {...props}>
      <MegaNavClient />
      <div className={styles.overlay} />
      <div className={styles.container}>
        <Row className={styles.row}>
          <div className={styles.navStart}>
            <LogoPicture
              url="/"
              darkLogoSrc={darkLogoSrc}
              darkLogoSize={{ width: 85, height: 24 }}
              lightLogoSrc={lightLogoSrc}
              lightLogoSize={{ width: 124, height: 35 }}
            />

            <div className={classNames(styles.mobileNav, styles.paddingContainer, styles.navOpen)}>
              <div className={styles.serviceNavigationMenu}>
                <MenuItem
                  label={translations.MENU_ITEMS.OUR_SERVICES}
                  htmlFor="serviceMenuToggle"
                  desktopIcon="arrows/chevron-down"
                />
              </div>

              <div className={styles.desktopServiceMenuContainer}>
                <div className={classNames(styles.serviceContainer, styles.desktopServiceMenu)}>
                  {/* {translations.SERVICES_SUBMENU.map((item) => (
                    <ServiceItem key={item.KEY} serviceKey={item.KEY} value={item.VALUE} icon={item.ICON} />
                  ))} */}

                  {/* MOT */}
                  <label
                    className={classNames(styles.service, styles.motNav)}
                    htmlFor="motToggle"
                    aria-hidden="true"
                    tabIndex={-1}
                    role="button"
                    data-analytics={translations.SERVICES_SUBMENU.MOT.VALUE}
                  >
                    <div className={styles.serviceStart}>
                      <div className={classNames(styles.iconContainer, styles.motBackground)}>
                        <Icon
                          className={styles.icon}
                          id={translations.SERVICES_SUBMENU.MOT.ICON}
                          legacy={false}
                          height={16}
                          width={16}
                        />
                      </div>
                      <p>{translations.SERVICES_SUBMENU.MOT.VALUE}</p>
                    </div>
                    <Icon className={styles.mobileIcon} id="arrows/chevron-right" legacy={false} />
                    <Icon className={styles.desktopIcon} id="arrows/chevron-down" legacy={false} />
                  </label>
                  <div className={styles.desktopNav}>
                    {/* MOT */}
                    <div
                      className={classNames(
                        styles.mobileNav,
                        styles.sideMenu,
                        styles.serviceMenu,
                        styles.motMenu
                      )}
                    >
                      <label
                        data-analytics="back-to-menu"
                        htmlFor="resetToggle"
                        className={classNames(styles.returnToMenu, styles.paddingContainer)}
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <Icon id="arrows/arrow-left" legacy={false} />
                        <p>{translations.MISC.MAIN_MENU}</p>
                      </label>
                      <div
                        className={classNames(styles.scrollable, styles.paddingContainer, {
                          [styles.noCtaContainer]: !translations.CONTENT.MOT.CTA,
                        })}
                      >
                        {translations.CONTENT.MOT.CTA && (
                          <div className={styles.submenuCtaContainer}>
                            <div className={styles.submenuCtaHeading}>
                              <p>{translations.MISC.QUICK_LINKS}</p>
                            </div>
                            {translations.CONTENT.MOT.CTA.map((cta) => (
                              <Cta
                                key={cta.URL}
                                cta={cta}
                                iconBackground={['motBackground', 'servicingBackground']}
                              />
                            ))}
                          </div>
                        )}
                        {translations.CONTENT.MOT.CONTENT?.LOCATIONS && (
                          <div className={styles.optionsMenuContainer}>
                            <div className={styles.optionsMenuHeading}>
                              <p>{translations.CONTENT.MOT.CONTENT.LOCATIONS.HEADER}</p>
                            </div>
                            <div className={classNames(styles.optionsMenuItems, styles.motLocations)}>
                              {translations.CONTENT.MOT.CONTENT.LOCATIONS.ITEMS.map(({ VALUE, URL }) => (
                                <a key={VALUE} href={URL} aria-hidden="true" tabIndex={-1}>
                                  {VALUE}
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* SERVICING */}
                  <label
                    className={classNames(styles.service, styles.servicingNav)}
                    htmlFor="servicingToggle"
                    aria-hidden="true"
                    tabIndex={-1}
                    role="button"
                    data-analytics={translations.SERVICES_SUBMENU.SERVICING.VALUE}
                  >
                    <div className={styles.serviceStart}>
                      <div className={classNames(styles.iconContainer, styles.servicingBackground)}>
                        <Icon
                          className={styles.icon}
                          id={translations.SERVICES_SUBMENU.SERVICING.ICON}
                          legacy={false}
                          height={16}
                          width={16}
                        />
                      </div>
                      <p>{translations.SERVICES_SUBMENU.SERVICING.VALUE}</p>
                    </div>
                    <Icon className={styles.mobileIcon} id="arrows/chevron-right" legacy={false} />
                    <Icon className={styles.desktopIcon} id="arrows/chevron-down" legacy={false} />
                  </label>
                  <div className={styles.desktopNav}>
                    {/* SERVICING */}
                    <div
                      className={classNames(
                        styles.mobileNav,
                        styles.sideMenu,
                        styles.serviceMenu,
                        styles.servicingMenu
                      )}
                    >
                      <label
                        data-analytics="back-to-menu"
                        htmlFor="resetToggle"
                        className={classNames(styles.returnToMenu, styles.paddingContainer)}
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <Icon id="arrows/arrow-left" legacy={false} />
                        <p>{translations.MISC.MAIN_MENU}</p>
                      </label>
                      <div
                        className={classNames(styles.scrollable, styles.paddingContainer, {
                          [styles.noCtaContainer]: !translations.CONTENT.SERVICING.CTA,
                        })}
                      >
                        {translations.CONTENT.SERVICING.CTA && (
                          <div className={styles.submenuCtaContainer}>
                            <div className={styles.submenuCtaHeading}>
                              <p>{translations.MISC.QUICK_LINKS}</p>
                            </div>
                            {translations.CONTENT.SERVICING.CTA.map((cta) => (
                              <Cta key={cta.URL} cta={cta} iconBackground="servicingBackground" />
                            ))}
                          </div>
                        )}
                        {translations.CONTENT.SERVICING.CONTENT?.LOCATIONS && (
                          <div className={styles.optionsMenuContainer}>
                            <div className={styles.optionsMenuHeading}>
                              <p>{translations.CONTENT.SERVICING.CONTENT.LOCATIONS.HEADER}</p>
                              <p className={styles.subheading}>
                                {translations.CONTENT.SERVICING.CONTENT.LOCATIONS.SUBHEADER}
                              </p>
                            </div>
                            <div className={classNames(styles.optionsMenuItems, styles.servicingLocations)}>
                              {translations.CONTENT.SERVICING.CONTENT.LOCATIONS.ITEMS.map(
                                ({ VALUE, URL }) => (
                                  <a key={VALUE} href={URL} aria-hidden="true" tabIndex={-1}>
                                    {VALUE}
                                  </a>
                                )
                              )}
                            </div>
                          </div>
                        )}
                        {translations.CONTENT.SERVICING.CONTENT?.MAKES && (
                          <div className={styles.optionsMenuContainer}>
                            <div className={styles.optionsMenuHeading}>
                              <p>{translations.CONTENT.SERVICING.CONTENT.MAKES.HEADER}</p>
                              <p className={styles.subheading}>
                                {translations.CONTENT.SERVICING.CONTENT.MAKES.SUBHEADER}
                              </p>
                            </div>
                            <div className={classNames(styles.optionsMenuItems, styles.servicingCars)}>
                              {translations.CONTENT.SERVICING.CONTENT.MAKES.ITEMS.map(({ VALUE, URL }) => (
                                <a key={VALUE} href={URL} aria-hidden="true" tabIndex={-1}>
                                  {VALUE}
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* REPAIRS */}
                  <label
                    className={classNames(styles.service, styles.repairsNav)}
                    htmlFor="repairsToggle"
                    aria-hidden="true"
                    tabIndex={-1}
                    role="button"
                    data-analytics={translations.SERVICES_SUBMENU.REPAIRS.VALUE}
                  >
                    <div className={styles.serviceStart}>
                      <div className={classNames(styles.iconContainer, styles.repairsBackground)}>
                        <Icon
                          className={styles.icon}
                          id={translations.SERVICES_SUBMENU.REPAIRS.ICON}
                          legacy={false}
                          height={16}
                          width={16}
                        />
                      </div>
                      <p>{translations.SERVICES_SUBMENU.REPAIRS.VALUE}</p>
                    </div>
                    <Icon className={styles.mobileIcon} id="arrows/chevron-right" legacy={false} />
                    <Icon className={styles.desktopIcon} id="arrows/chevron-down" legacy={false} />
                  </label>
                  <div className={styles.desktopNav}>
                    {/* REPAIRS */}
                    <div
                      className={classNames(
                        styles.mobileNav,
                        styles.sideMenu,
                        styles.serviceMenu,
                        styles.repairsMenu
                      )}
                    >
                      <label
                        data-analytics="back-to-menu"
                        htmlFor="resetToggle"
                        className={classNames(styles.returnToMenu, styles.paddingContainer)}
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <Icon id="arrows/arrow-left" legacy={false} />
                        <p>{translations.MISC.MAIN_MENU}</p>
                      </label>
                      <div
                        className={classNames(styles.scrollable, styles.paddingContainer, {
                          [styles.noCtaContainer]: !translations.CONTENT.REPAIRS.CTA,
                        })}
                      >
                        {translations.CONTENT.REPAIRS.CTA && (
                          <div className={styles.submenuCtaContainer}>
                            <div className={styles.submenuCtaHeading}>
                              <p>{translations.MISC.QUICK_LINKS}</p>
                            </div>
                            {translations.CONTENT.REPAIRS.CTA.map((cta) => (
                              <Cta key={cta.URL} cta={cta} iconBackground="repairsBackground" />
                            ))}
                          </div>
                        )}
                        {translations.CONTENT.REPAIRS.CONTENT?.LOCATIONS && (
                          <div className={styles.optionsMenuContainer}>
                            <div className={styles.optionsMenuHeading}>
                              <p>{translations.CONTENT.REPAIRS.CONTENT.LOCATIONS.HEADER}</p>
                            </div>
                            <div className={classNames(styles.optionsMenuItems, styles.optionsMenuColumn)}>
                              {translations.CONTENT.REPAIRS.CONTENT.LOCATIONS.ITEMS.map(({ VALUE, URL }) => (
                                <a key={VALUE} href={URL} aria-hidden="true" tabIndex={-1}>
                                  {VALUE}
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* DIAGNOSTICS */}
                  <label
                    className={classNames(styles.service, styles.diagnosticsNav)}
                    htmlFor="diagnosticsToggle"
                    aria-hidden="true"
                    tabIndex={-1}
                    role="button"
                    data-analytics={translations.SERVICES_SUBMENU.DIAGNOSTICS.VALUE}
                  >
                    <div className={styles.serviceStart}>
                      <div className={classNames(styles.iconContainer, styles.diagnosticsBackground)}>
                        <Icon
                          className={styles.icon}
                          id={translations.SERVICES_SUBMENU.DIAGNOSTICS.ICON}
                          legacy={false}
                          height={16}
                          width={16}
                        />
                      </div>
                      <p>{translations.SERVICES_SUBMENU.DIAGNOSTICS.VALUE}</p>
                    </div>
                    <Icon className={styles.mobileIcon} id="arrows/chevron-right" legacy={false} />
                    <Icon className={styles.desktopIcon} id="arrows/chevron-down" legacy={false} />
                  </label>
                  <div className={styles.desktopNav}>
                    {/* DIAGNOSTICS */}
                    <div
                      className={classNames(
                        styles.mobileNav,
                        styles.sideMenu,
                        styles.serviceMenu,
                        styles.diagnosticsMenu
                      )}
                    >
                      <label
                        data-analytics="back-to-menu"
                        htmlFor="resetToggle"
                        className={classNames(styles.returnToMenu, styles.paddingContainer)}
                        aria-hidden="true"
                        tabIndex={-1}
                      >
                        <Icon id="arrows/arrow-left" legacy={false} />
                        <p>{translations.MISC.MAIN_MENU}</p>
                      </label>
                      <div
                        className={classNames(styles.scrollable, styles.paddingContainer, {
                          [styles.noCtaContainer]: !translations.CONTENT.DIAGNOSTICS.CTA,
                        })}
                      >
                        {translations.CONTENT.DIAGNOSTICS.CTA && (
                          <div className={styles.submenuCtaContainer}>
                            <div className={styles.submenuCtaHeading}>
                              <p>{translations.MISC.QUICK_LINKS}</p>
                            </div>
                            {translations.CONTENT.DIAGNOSTICS.CTA.map((cta) => (
                              <Cta key={cta.URL} cta={cta} iconBackground="diagnosticsBackground" />
                            ))}
                          </div>
                        )}
                        {translations.CONTENT.DIAGNOSTICS.CONTENT?.LOCATIONS && (
                          <div className={styles.optionsMenuContainer}>
                            <div className={styles.optionsMenuHeading}>
                              <p>{translations.CONTENT.DIAGNOSTICS.CONTENT.LOCATIONS.HEADER}</p>
                            </div>
                            <div
                              className={classNames(
                                styles.optionsMenuItems,
                                styles.optionsMenuColumn,
                                styles.diagnosticsColumns
                              )}
                            >
                              {translations.CONTENT.DIAGNOSTICS.CONTENT.LOCATIONS.ITEMS.map(
                                ({ VALUE, URL }) => (
                                  <a key={VALUE} href={URL} aria-hidden="true" tabIndex={-1}>
                                    {VALUE}
                                  </a>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {translations.MENU_ITEMS.GARAGE && (
                <div className={styles.garageNavigationMenu}>
                  <MenuItem
                    label={translations.MENU_ITEMS.GARAGE}
                    htmlFor="garageToggle"
                    mobileIcon="arrows/arrow-right"
                    desktopIcon="arrows/chevron-down"
                  />
                </div>
              )}
              <div className={styles.desktopNav}>
                {/* GARAGES */}
                {translations.CONTENT.GARAGES && (
                  <div className={classNames(styles.mobileNav, styles.sideMenu, styles.garageMenu)}>
                    <label
                      data-analytics="back-to-menu"
                      htmlFor="garageToggle"
                      className={classNames(styles.returnToMenu, styles.paddingContainer)}
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <Icon id="arrows/arrow-left" legacy={false} />
                      <p>{translations.MISC.MAIN_MENU}</p>
                    </label>
                    <div
                      className={classNames(
                        styles.scrollable,
                        styles.paddingContainer,
                        styles.noCtaContainer
                      )}
                    >
                      {translations.CONTENT.GARAGES.CONTENT?.LOCATIONS && (
                        <div className={styles.optionsMenuContainer}>
                          <div className={styles.optionsMenuHeading}>
                            <p>{translations.CONTENT.GARAGES.CONTENT.LOCATIONS.HEADER}</p>
                          </div>
                          <div className={classNames(styles.optionsMenuItems, styles.optionsMenuMargin)}>
                            {translations.CONTENT.GARAGES.CONTENT.LOCATIONS.ITEMS.map(({ VALUE, URL }) => (
                              <a key={VALUE} href={URL} aria-hidden="true" tabIndex={-1}>
                                {VALUE}
                              </a>
                            ))}
                          </div>
                          {translations.CONTENT.GARAGES.CONTENT.VIEW_ALL && (
                            <div className={styles.optionsMenuViewAll}>
                              <a
                                href={translations.CONTENT.GARAGES.CONTENT.VIEW_ALL.URL}
                                aria-hidden="true"
                                tabIndex={-1}
                              >
                                {translations.CONTENT.GARAGES.CONTENT.VIEW_ALL.VALUE}
                              </a>
                              <Icon
                                className={styles.viewAllIcon}
                                id="arrows/arrow-right"
                                height={16}
                                width={16}
                                legacy={false}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {translations.MENU_ITEMS.HOW_IT_WORKS?.URL && translations.MENU_ITEMS.HOW_IT_WORKS.VALUE && (
                <div className={classNames(styles.howItWorksNavigationLink)}>
                  <div className={styles.linkContainer}>
                    <span className={styles.link}>
                      <a href={translations.MENU_ITEMS.HOW_IT_WORKS.URL}>
                        {translations.MENU_ITEMS.HOW_IT_WORKS.VALUE}
                      </a>
                    </span>
                  </div>
                </div>
              )}

              <div className={styles.blogNavigationMenu}>
                <MenuItem
                  label={translations.MENU_ITEMS.BLOG}
                  htmlFor="blogToggle"
                  mobileIcon="arrows/arrow-right"
                  desktopIcon="arrows/chevron-down"
                />
              </div>
              <div className={styles.desktopNav}>
                {/* BLOG */}
                <div className={classNames(styles.mobileNav, styles.sideMenu, styles.blogMenu)}>
                  <label
                    data-analytics="back-to-menu"
                    htmlFor="blogToggle"
                    className={classNames(styles.returnToMenu, styles.paddingContainer)}
                    aria-hidden="true"
                    tabIndex={-1}
                  >
                    <Icon id="arrows/arrow-left" legacy={false} />
                    <p>{translations.MISC.MAIN_MENU}</p>
                  </label>
                  <div className={classNames(styles.scrollable, styles.paddingContainer)}>
                    {translations.CONTENT.BLOG.CTA && (
                      <div className={styles.submenuCtaContainer}>
                        <div className={styles.submenuCtaHeading}>
                          <p>{translations.MISC.QUICK_LINKS}</p>
                        </div>
                        {translations.CONTENT.BLOG.CTA.map((cta) => (
                          <Cta key={cta.URL} cta={cta} iconBackground="blogBackground" />
                        ))}
                      </div>
                    )}
                    <div className={styles.blogContainer}>
                      {translations.CONTENT.BLOG.CONTENT?.BLOGS?.FEATURED_BLOG && (
                        <div className={styles.popularBlogsContainer}>
                          <div className={styles.popularBlogsHeader}>
                            <p>{translations.CONTENT.BLOG.CONTENT.BLOGS.POPULAR_HEADER}</p>
                          </div>
                          <a
                            href={translations.CONTENT.BLOG.CONTENT.BLOGS.FEATURED_BLOG.URL}
                            aria-hidden="true"
                            tabIndex={-1}
                          >
                            <div className={styles.featuredBlog}>
                              <div className={styles.featuredBlogImage}>
                                <Img
                                  provider="cloudinary"
                                  className={styles.previewImage}
                                  src={translations.CONTENT.BLOG.CONTENT.BLOGS.FEATURED_BLOG.IMAGE.URL}
                                  alt={translations.CONTENT.BLOG.CONTENT.BLOGS.FEATURED_BLOG.IMAGE.ALT}
                                  fill
                                />
                              </div>
                              <div className={styles.featuredBlogContent}>
                                <p className={styles.featuredBlogTitle}>
                                  {translations.CONTENT.BLOG.CONTENT.BLOGS.FEATURED_BLOG.HEADER}
                                </p>
                                <p className={styles.featuredBlogDescription}>
                                  {translations.CONTENT.BLOG.CONTENT.BLOGS.FEATURED_BLOG.DESCRIPTION}
                                </p>
                              </div>
                            </div>
                          </a>
                          {translations.CONTENT.BLOG.CONTENT?.BLOGS?.POPULAR_BLOGS.map((blog) => (
                            <a key={blog.URL} href={blog.URL} aria-hidden="true" tabIndex={-1}>
                              <div className={styles.latestBlogsContainer}>
                                <p>{blog.VALUE}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                      )}
                      <div className={styles.latestBlogs}>
                        <div className={styles.latestBlogsTitle}>
                          <p>{translations.CONTENT.BLOG.CONTENT?.BLOGS?.LATEST_BLOGS_HEADER}</p>
                        </div>
                        {dynamicBlogData.map((blog) => (
                          <a key={blog.urlPath} href={blog.urlPath} aria-hidden="true" tabIndex={-1}>
                            <div className={styles.latestBlogsContainer}>
                              <p>{blog.title}</p>
                            </div>
                          </a>
                        ))}
                        {translations.CONTENT.BLOG.CONTENT?.VIEW_ALL && (
                          <div className={styles.optionsMenuViewAll} aria-hidden="true" tabIndex={-1}>
                            <a
                              href={translations.CONTENT.BLOG.CONTENT?.VIEW_ALL.URL}
                              aria-hidden="true"
                              tabIndex={-1}
                            >
                              {translations.CONTENT.BLOG.CONTENT?.VIEW_ALL.VALUE}
                            </a>
                            <Icon
                              className={styles.viewAllIcon}
                              id="arrows/arrow-right"
                              height={16}
                              width={16}
                              legacy={false}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {navigationMenuFooter || (
                <div className={styles.loginMobileLinkContainer}>
                  <a
                    href={translations.MISC.LOGIN.URL}
                    aria-hidden="true"
                    tabIndex={-1}
                    className={styles.loginMobileLink}
                  >
                    <p>{translations.MISC.LOGIN.VALUE}</p>
                    <div className={styles.loginIconContainer}>
                      <Icon
                        className={styles.icon}
                        id="general/log-in-01"
                        height={16}
                        width={16}
                        legacy={false}
                      />
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className={styles.navEnd}>
            <div className={styles.children}>
              {/**
               * Things contextual to the page, we should avoid hardcoding page logic inside this component.
               * Instead create specialized components that compose components based on the page.
               * eg. `templates/MegaNavBar`.
               */}
              {children}
            </div>

            {showLoginLink && (
              <a className={classNames('login', styles.login)} href={translations.MISC.LOGIN.URL}>
                {translations.MISC.LOGIN.VALUE}
              </a>
            )}
            <label
              role="button"
              htmlFor="menuToggle"
              className={styles.mobileMenu}
              aria-hidden="true"
              tabIndex={-1}
              data-analytics="mobile-menu"
            >
              <Icon className={styles.menuOpen} id="general/menu-01" legacy={false} />
              <Icon className={styles.menuClose} id="general/x-close" legacy={false} />
            </label>
          </div>
        </Row>
      </div>
    </nav>
  );
}

MegaNav.displayName = 'MegaNavBar';
